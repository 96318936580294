import { Api } from '@/services/apiService';

class quayGroupApi {
    static index() {
        return Api.get(`/quay/groups`, { baseURL: '/new-api' });
    }

    static create(data) {
        return Api.post(`/quay/create-group`, data, { baseURL: '/new-api' });
    }

    static delete(groupId) {
        return Api.delete(`/quay/remove-group/${groupId}`, { baseURL: '/new-api' });
    }

    static addQuayGroup(data) {
        return Api.post(`/quay/add-quay-group`, data, { baseURL: '/new-api' });
    }

    static removeQuayGroup(quayId, groupId) {
        return Api.delete(`/quay/remove-quay-group/${quayId}/${groupId}`, { baseURL: '/new-api' });
    }
}

export default quayGroupApi;
