import quayApi from '@/services/quay/quayApi';
import quayGroupApi from '@/services/quay/quayGroupApi';
import formatLocation from '@/helpers/formatLocation.helper';
import { throttle } from 'lodash';
import eventPCM from '@/services/port-call-manager/eventPCM';
import formatEvent from '@/helpers/formatEvent.helper';
import getStatementsForQuaysEvents from '@/helpers/getStatementsForQuaysEvents.helper.js';
import formatEventsToVisits from '@/helpers/formatEventsToVisits';
import quayRelationApi from '@/services/quay/quayRelationApi';

const duplicateQuayURNWithoutCoordinates = 'urn:mrn:stm:location:nosvg:tug_zone:tug cruiseship';

export const quays = {
    state: {
        quays: [],
        quaysEvents: [],
        groups: [],
        selectedGroup: null,
        selectedQuay: null,
        selectedQuayEvents: null,
        selectedQuayVisitsInfo: null,
    },
    getters: {
        quays: state => {
            return state.quays;
        },
        groups: state => {
            return state.groups;
        },
        selectedGroup: state => {
            return state.selectedGroup;
        },
        quaysNames: state => {
            return state.quays.map(quay => {
                return {
                    id: quay.id,
                    URN: quay.URN,
                    name: quay.name,
                };
            });
        },
        quaysEvents: state => {
            return state.quaysEvents;
        },
        selectedQuay: state => {
            return state.selectedQuay;
        },
        selectedQuayId: state => {
            return state.selectedQuay && state.selectedQuay.URN;
        },
        selectedQuayEvents: state => {
            return state.selectedQuayEvents;
        },
        selectedQuayVisitsInfo: state => {
            return state.selectedQuayVisitsInfo;
        },
    },
    mutations: {
        // Quay main
        setQuays(state, quays) {
            state.quays = quays;
        },
        addQuay(state, quay) {
            state.quays = [...state.quays, quay];
        },
        setSelectedQuaysEvents(state, events) {
            state.quaysEvents = events;
        },
        setQuaysFromCustomLists(state, options) {
            state.quays = options;
        },

        // Quay groups
        setGroups(state, groups) {
            state.groups = groups;
        },
        setSelectedGroup(state, group) {
            state.selectedGroup = group;
        },
        addGroup(state, group) {
            state.groups = [...state.groups, group];
        },
        deleteGroup(state, groupId) {
            state.groups = state.groups.filter(group => group.id !== groupId);
        },
        updateGroup(state, group) {
            const currentGroupIndex = state.groups.findIndex(item => item.id === group.id);
            state.groups[currentGroupIndex] = group;
            state.selectedGroup = group;
            state.groups = [...state.groups];
        },

        // Selected quay
        setSelectedQuay(state, quay) {
            state.selectedQuay = quay;
        },
        setSelectedQuayEvents(state, events) {
            state.selectedQuayEvents = events;
        },
        setSelectedQuayVisitsInfo(state, visitsInfo) {
            state.selectedQuayVisitsInfo = visitsInfo;
        },

        // Quay relations
        deleteRelation(state, relationId) {
            state.selectedQuay.relations = state.selectedQuay.relations.filter(relation => relation.id !== relationId);
        },

        addRelation(state, relation) {
            state.selectedQuay.relations = [...state.selectedQuay.relations, relation];
        },
    },
    actions: {
        // Quay main
        getQuays: throttle(async ({ commit }) => {
            const response = await quayApi.index();
            response.data = await response.data.filter(quay => {
                return quay.URN !== duplicateQuayURNWithoutCoordinates || !quay.area.coordinates.length;
            });
            const quays = await Promise.all(response.data.map(formatLocation));
            commit('setQuays', quays);
        }, 1000),
        async storeQuay({ commit }, data) {
            const response = await quayApi.store(data);
            commit('addQuay', response.data);
        },
        setQuaysFromCustomLists({ commit }, options) {
            if (options && options.length) {
                options = options.filter(item => item.checked).flatMap(option => option.quays);
                commit('setQuaysFromCustomLists', options);
            }
        },
        async getSelectedQuaysEvents({ commit }, params) {
            const response = await eventPCM.index(params);
            let events = await Promise.all(response.data.map(event => formatEvent(event, true)));
            events = await getStatementsForQuaysEvents(events);

            commit('setSelectedQuaysEvents', events);
        },

        // Quay groups
        async getQuaysGroups({ commit }) {
            const response = await quayGroupApi.index();
            commit('setGroups', response.data);
        },
        async storeGroup({ commit }, data) {
            const response = await quayGroupApi.create(data);
            commit('addGroup', response.data);
            commit('setSelectedGroup', response.data);
        },
        async removeGroup({ commit }, groupId) {
            await quayGroupApi.delete(groupId);
            commit('deleteGroup', groupId);
            commit('setSelectedGroup', null);
        },
        async storeGroupedQuay({ commit }, data) {
            const response = await quayGroupApi.addQuayGroup(data);
            commit('updateGroup', response.data);
        },
        async removeGroupedQuay({ commit }, payload) {
            const response = await quayGroupApi.removeQuayGroup(payload.quayId, payload.groupId);
            commit('updateGroup', response.data);
        },

        // Selected quay
        async getQuay({ commit }, id) {
            const response = await quayApi.show(id);
            const quay = await formatLocation(response.data);
            commit('setSelectedQuay', quay);
        },
        async getQuayEvents({ commit }, params) {
            const response = await eventPCM.index(params);
            let events = await Promise.all(response.data.map(event => formatEvent(event, true)));
            events = await getStatementsForQuaysEvents(events);

            commit('setSelectedQuayEvents', events);
        },
        async getQuayVisitsInfo({ commit }, params) {
            const response = await eventPCM.index(params);
            let visitsInfo = {};
            for (let event of response.data) {
                visitsInfo = await formatEventsToVisits(visitsInfo, event);
            }
            commit('setSelectedQuayVisitsInfo', visitsInfo);
        },

        // Quay relations
        async removeRelation({ commit }, relationId) {
            await quayRelationApi.delete(relationId);
            commit('deleteRelation', relationId);
        },

        async storeRelation({ commit }, data) {
            const response = await quayRelationApi.store(data);
            commit('addRelation', response.data);
        },
    },
};
