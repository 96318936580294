import { Api } from '@/services/apiService';

export class portCallStateApi {
    static create(data) {
        return Api.post(`/portcall/create-state`, data, { baseURL: '/new-api' });
    }

    static update(data, id) {
        return Api.put(`/portcall/update-state/${id}`, data, { baseURL: '/new-api' });
    }
}
