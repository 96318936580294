import { setApiToken } from '@/services/apiService';
import Cookies from 'js-cookie';
import localStorage from '@/services/local-storage/localStorage';
import getUserByToken from '@/helpers/getUserByToken';

export const auth = {
    state: {
        user: {
            favouriteQuays: [],
            vivaStations: [],
            customLists: { quay: [], vessel: [] },
            stationaryServiceLocations: [],
            nauticalServiceLocations: [],
            locationStateLocations: [],
            favouritePortcalls: {
                checked: false,
                portcalls: [],
            },
        },
        userInfo: {},
        tokenInfo: null,
    },
    getters: {
        user: state => {
            return state.user;
        },
        userInfo: state => {
            return state.userInfo;
        },
        token: state => {
            return state.tokenInfo && state.tokenInfo.token;
        },
        customQuayLists: state => {
            return state.user && state.user.customLists && state.user.customLists.quay;
        },
        customVesselLists: state => {
            return state.user && state.user.customLists && state.user.customLists.vessel;
        },
        vivaStations: state => {
            return state.user && state.user.vivaStations;
        },
        favouritePortcalls: state => {
            return state.user && state.user.favouritePortcalls;
        },
        favouriteQuays: state => {
            return state.user && state.user.favouriteQuays;
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setTokenInfo(state, { tokenType, token, expiresIn }) {
            setApiToken({ tokenType, token });
            expiresIn && Cookies.set('USER_TOKEN', { tokenType, token }, { expires: expiresIn / (24 * 3600) });
            state.tokenInfo = { tokenType, token };
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        addList(state, list) {
            state.user.customLists[list.type].push(list);
            localStorage.create('customLists', state.user.customLists);
        },
        removeList(state, list) {
            state.user.customLists[list.type] = state.user.customLists[list.type].filter(savedList => {
                return savedList.name !== list.name;
            });
            localStorage.create('customLists', state.user.customLists);
        },
        addItemToList(state, { list, item }) {
            state.user.customLists[list.type]
                .find(savedList => savedList.name === list.name)
                [`${list.type}s`].push(item);
            localStorage.create('customLists', state.user.customLists);
        },
        removeItemFromList(state, { list, item, itemKey }) {
            state.user.customLists[list.type].find(savedList => savedList.name === list.name)[
                `${list.type}s`
            ] = state.user.customLists[list.type]
                .find(savedList => savedList.name === list.name)
                [`${list.type}s`].filter(existingItem => existingItem[itemKey] !== item[itemKey]);
            localStorage.create('customLists', state.user.customLists);
        },
        addLists(state, lists) {
            state.user.customLists[lists[0].type] = lists;
        },
        addVivaStation(state, station) {
            state.user.vivaStations.push(station);
        },
        removeVivaStation(state, stationId) {
            state.user.vivaStations = state.user.vivaStations.filter(station => station.ID !== stationId);
        },
        toggleFavouritePortcall(state, portCallId) {
            if (state.user.favouritePortcalls.portcalls.includes(portCallId)) {
                state.user.favouritePortcalls.portcalls = state.user.favouritePortcalls.portcalls.filter(
                    existingPortCallId => {
                        return existingPortCallId !== portCallId;
                    }
                );
            } else {
                state.user.favouritePortcalls.portcalls.push(portCallId);
            }
            localStorage.create('favouritePortcalls', state.user.favouritePortcalls);
        },
        toggleFavouriteQuay(state, quayURN) {
            if (state.user.favouriteQuays.includes(quayURN)) {
                state.user.favouriteQuays = state.user.favouriteQuays.filter(
                    favoriteQuayURN => favoriteQuayURN !== quayURN
                );
            } else {
                state.user.favouriteQuays.push(quayURN);
            }
            localStorage.create('favouriteQuays', state.user.favouriteQuays);
        },
        addFavouritesPortcalls(state, params) {
            state.user.favouritePortcalls.portcalls = params;
        },
        setIsFavouritePortcalls(state, params) {
            state.user.favouritePortcalls.checked = params;
            localStorage.create('favouritePortcalls', state.user.favouritePortcalls);
        },
        addFavoriteQuays(state, params) {
            state.user.favouriteQuays = params;
        },
    },
    actions: {
        login({ commit }, tokenInfo) {
            commit('setTokenInfo', tokenInfo);
            const userInfo = getUserByToken(tokenInfo.token);
            commit('setUserInfo', userInfo);
            return tokenInfo.token;
        },
        logOut({ commit }) {
            Cookies.remove('USER_TOKEN');
            window.sessionStorage.clear();
            commit('setTokenInfo', { tokenType: null, token: null });
            commit('setUser', null);
        },
        addCustomList({ commit }, list) {
            commit('addList', list);
        },
        removeCustomList({ state, commit }, list) {
            commit('removeList', list);
            commit('removeListsFromFilters', { type: list.type, lists: state.user.customLists[list.type] });
        },
        addItemToCustomList({ state, commit }, { list, item }) {
            commit('addItemToList', { list, item });
            commit('addListsToFilters', { type: list.type, lists: state.user.customLists[list.type] });
        },
        removeItemFromCustomList({ state, commit }, { list, item, itemKey }) {
            commit('removeItemFromList', { list, item, itemKey });
            commit('removeListsFromFilters', { type: list.type, lists: state.user.customLists[list.type] });
        },
        addCustomLists({ commit }, lists) {
            commit('addLists', lists);
        },
    },
};
