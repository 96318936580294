import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/modules/auth';
import { portCalls } from '@/store/modules/portCalls';
import { selectedPortCall } from '@/store/modules/selectedPortCall';
import { sidebar } from '@/store/modules/sidebar';
import { vesselTypes } from '@/store/modules/vesselTypes';
import { portCallDefinition } from '@/store/modules/portCallDefinition';
import { portStatistic } from '@/store/modules/portStatistic';
import { quays } from '@/store/modules/quays';
import { customLayout } from '@/store/modules/customLayout';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        portCalls,
        selectedPortCall,
        sidebar,
        vesselTypes,
        portCallDefinition,
        portStatistic,
        quays,
        customLayout,
    },
});
