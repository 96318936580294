import { Api } from '@/services/apiService';

class quayRelationApi {
    static store(data) {
        return Api.post(`/quay/relations`, data, { baseURL: '/new-api' });
    }

    static delete(quayRelationId) {
        return Api.delete(`/quay/relations/${quayRelationId}`, { baseURL: '/new-api' });
    }
}

export default quayRelationApi;
