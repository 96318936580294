import stateCatalogue from '@/services/port-cdm/stateCatalogue';
import { formatPortcallStateDefinitions } from '@/helpers/formatPortcallStateDefinitions.helper';
import { portCallStateApi } from '@/services/port-call-state/portCallStateApi';
import localStorage from '@/services/local-storage/localStorage';

export const portCallDefinition = {
    state: {
        statesDefinitions: [],
        recentStates: [],
    },
    getters: {
        statesDefinitions: state => {
            return state.statesDefinitions;
        },
        recentStates: state => {
            return state.recentStates;
        },
    },
    mutations: {
        setStatesDefinitions(state, statesDefinitions) {
            state.statesDefinitions = statesDefinitions;
        },
        addStateDefinition(state, stateDefinition) {
            state.statesDefinitions.unshift(stateDefinition);
        },
        editStateDefinition(state, stateDefinition) {
            const existsAtIndex = state.statesDefinitions.findIndex(state => state.id === stateDefinition.id);
            const existsAtLoaclIndex = state.recentStates.findIndex(
                item => item === state.statesDefinitions[existsAtIndex].stateId
            );

            state.recentStates[existsAtLoaclIndex] = stateDefinition.stateId;
            localStorage.create('recentStates', state.recentStates);

            state.statesDefinitions[existsAtIndex] = stateDefinition;
            state.statesDefinitions = [...state.statesDefinitions];
        },
        addRecentState(state, newState) {
            !state.recentStates.includes(newState) && state.recentStates.push(newState);
            localStorage.create('recentStates', state.recentStates);
        },
        removeRecentState(state, newState) {
            state.recentStates = state.recentStates.filter(recentState => recentState !== newState);
            localStorage.create('recentStates', state.recentStates);
        },
        addRecentStates(state, params) {
            state.recentStates = params;
        },
    },
    actions: {
        async getStatesDefinitions({ commit }) {
            const response = await stateCatalogue.index();
            const statesDefinitions = formatPortcallStateDefinitions(response.data);
            commit('setStatesDefinitions', statesDefinitions);
        },
        async createStateDefinition({ commit }, data) {
            const response = await portCallStateApi.create(data);
            const stateDefinition = formatPortcallStateDefinitions([response.data])[0];
            commit('addStateDefinition', stateDefinition);
        },
        async updateStateDefinition({ commit }, payload) {
            const response = await portCallStateApi.update(payload.data, payload.id);
            const stateDefinition = formatPortcallStateDefinitions([response.data])[0];
            commit('editStateDefinition', stateDefinition);
        },
    },
};
