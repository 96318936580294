<template>
    <div
        :tabindex="searchable ? -1 : tabindex"
        :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled, 'multiselect--above': isAbove }"
        @focus="activate()"
        @blur="searchable ? false : deactivate()"
        @keydown.self.down.prevent="pointerForward()"
        @keydown.self.up.prevent="pointerBackward()"
        @keypress.enter.tab.stop.self="addPointerElement($event)"
        @keyup.esc="deactivate()"
        class="multiselect"
    >
        <slot name="caret" :toggle="toggle">
            <div @mousedown.prevent.stop="toggle()" class="multiselect__select"></div>
        </slot>
        <slot name="clear" :search="search"></slot>
        <div ref="tags" class="multiselect__tags" :class="{ multiselect__tags_outline: langSelector }">
            <slot name="selection" :search="search" :remove="removeElement" :values="visibleValues" :is-open="isOpen">
                <div class="multiselect__tags-wrap" v-show="visibleValues.length > 0">
                    <template v-for="(option, index) of visibleValues" @mousedown.prevent>
                        <slot name="tag" :option="option" :search="search" :remove="removeElement">
                            <span class="multiselect__tag" :key="index">
                                <span v-text="getOptionLabel(option)"></span>
                                <i
                                    aria-hidden="true"
                                    tabindex="1"
                                    @keypress.enter.prevent="removeElement(option)"
                                    @mousedown.prevent="removeElement(option)"
                                    class="multiselect__tag-icon"
                                ></i>
                            </span>
                        </slot>
                    </template>
                </div>
                <template v-if="internalValue && internalValue.length > limit">
                    <slot name="limit">
                        <strong class="multiselect__strong" v-text="limitText(internalValue.length - limit)" />
                    </slot>
                </template>
            </slot>
            <transition name="multiselect__loading">
                <slot name="loading">
                    <div v-show="loading" class="multiselect__spinner" />
                </slot>
            </transition>
            <input
                ref="search"
                v-if="searchable"
                :name="name"
                :id="id"
                type="text"
                autocomplete="nope"
                :placeholder="placeholder"
                :style="inputStyle"
                :value="search"
                :disabled="disabled"
                :tabindex="tabindex"
                @input="updateSearch($event.target.value)"
                @focus.prevent="onFocusHandler"
                @blur.prevent="onBlurHandler"
                @keyup.esc="deactivate()"
                @keydown.down.prevent="pointerForward()"
                @keydown.up.prevent="pointerBackward()"
                @keypress.enter.prevent.stop.self="addPointerElement($event)"
                @keydown.delete.stop="removeLastElement()"
                class="multiselect__input"
            />
            <span v-if="isSingleLabelVisible" class="multiselect__single" @mousedown.prevent="toggle">
                <slot name="singleLabel" :option="singleValue">
                    <template>{{ currentOptionLabel }}</template>
                </slot>
            </span>
            <span v-if="isPlaceholderVisible" class="multiselect__placeholder" @mousedown.prevent="toggle">
                <slot name="placeholder">
                    {{ placeholder }}
                </slot>
            </span>
        </div>
        <transition name="multiselect">
            <div
                class="multiselect__content-wrapper"
                v-show="isOpen"
                @focus="activate"
                tabindex="-1"
                @mousedown.prevent
                ref="list"
            >
                <ul
                    class="multiselect__content"
                    :style="[contentStyle, { maxHeight: optimizedHeight + 'px' }]"
                    ref="elements"
                    @scroll="synchronizeScroll($event, 'scroll-area')"
                >
                    <slot name="beforeList"></slot>
                    <li v-if="multiple && max === internalValue.length">
                        <span class="multiselect__option">
                            <slot name="maxElements">
                                Maximum of {{ max }} options selected. First remove a selected option to select another.
                            </slot>
                        </span>
                    </li>
                    <template v-if="!max || internalValue.length < max">
                        <template v-for="(option, index) of filteredOptions">
                            <li class="multiselect__element" :key="index">
                                <span
                                    v-if="!(option && (option.$isLabel || option.$isDisabled))"
                                    :class="optionHighlight(index, option)"
                                    @click.stop="select(option)"
                                    @mouseenter.self="pointerSet(index)"
                                    :data-select="option && option.isTag ? tagPlaceholder : selectLabelText"
                                    :data-selected="selectedLabelText"
                                    :data-deselect="deselectLabelText"
                                    class="multiselect__option"
                                >
                                    <slot name="option" :option="option" :search="search">
                                        <span>{{ getOptionLabel(option) }}</span>
                                    </slot>
                                </span>
                                <span
                                    v-if="option && (option.$isLabel || option.$isDisabled)"
                                    :data-select="groupSelect && selectGroupLabelText"
                                    :data-deselect="groupSelect && deselectGroupLabelText"
                                    :class="groupHighlight(index, option)"
                                    @mouseenter.self="groupSelect && pointerSet(index)"
                                    @mousedown.prevent="selectGroup(option)"
                                    class="multiselect__option"
                                >
                                    <slot name="option" :option="option" :search="search">
                                        <span>{{ getOptionLabel(option) }}</span>
                                    </slot>
                                </span>
                            </li>
                            <mf-divider :key="`divider-${index}`" />
                        </template>
                    </template>
                    <li v-show="showNoResults && filteredOptions.length === 0 && search && !loading">
                        <span class="multiselect__option">
                            <slot name="noResult" :search="search">
                                No elements found. Consider changing the search query.
                            </slot>
                        </span>
                    </li>
                    <li v-show="showNoOptions && options.length === 0 && !search && !loading">
                        <span class="multiselect__option">
                            <slot name="noOptions">List is empty.</slot>
                        </span>
                    </li>
                    <slot name="afterList"></slot>
                </ul>
                <div
                    @scroll.prevent="synchronizeScroll($event, 'elements')"
                    class="multiselect__scroll"
                    :style="{ maxHeight: optimizedHeight - 16 + 'px' }"
                    ref="scroll-area"
                >
                    <div />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { multiselectMixin, pointerMixin } from 'vue-multiselect';

    export default {
        mixins: [multiselectMixin, pointerMixin],
        props: {
            /**
             * name attribute to match optional label element
             * @default ''
             * @type {String}
             */
            name: {
                type: String,
                default: '',
            },
            /**
             * String to show when pointing to an option
             * @default 'Press enter to select'
             * @type {String}
             */
            selectLabel: {
                type: String,
                default: 'Press enter to select',
            },
            /**
             * String to show when pointing to an option
             * @default 'Press enter to select'
             * @type {String}
             */
            selectGroupLabel: {
                type: String,
                default: 'Press enter to select group',
            },
            /**
             * String to show next to selected option
             * @default 'Selected'
             * @type {String}
             */
            selectedLabel: {
                type: String,
                default: 'Selected',
            },
            /**
             * String to show when pointing to an already selected option
             * @default 'Press enter to remove'
             * @type {String}
             */
            deselectLabel: {
                type: String,
                default: 'Press enter to remove',
            },
            /**
             * String to show when pointing to an already selected option
             * @default 'Press enter to remove'
             * @type {String}
             */
            deselectGroupLabel: {
                type: String,
                default: 'Press enter to deselect group',
            },
            /**
             * Decide whether to show pointer labels
             * @default true
             * @type {Boolean}
             */
            showLabels: {
                type: Boolean,
                default: false,
            },
            /**
             * Limit the display of selected options. The rest will be hidden within the limitText string.
             * @default 99999
             * @type {Integer}
             */
            limit: {
                type: Number,
                default: 99999,
            },
            /**
             * Sets maxHeight style value of the dropdown
             * @default 300
             * @type {Integer}
             */
            maxHeight: {
                type: Number,
                default: 300,
            },
            /**
             * Function that process the message shown when selected
             * elements pass the defined limit.
             * @default 'and * more'
             * @param {Int} count Number of elements more than limit
             * @type {Function}
             */
            limitText: {
                type: Function,
                default: count => `and ${count} more`,
            },
            /**
             * Set true to trigger the loading spinner.
             * @default False
             * @type {Boolean}
             */
            loading: {
                type: Boolean,
                default: false,
            },
            /**
             * Disables the multiselect if true.
             * @default false
             * @type {Boolean}
             */
            disabled: {
                type: Boolean,
                default: false,
            },
            /**
             * Fixed opening direction
             * @default ''
             * @type {String}
             */
            openDirection: {
                type: String,
                default: '',
            },
            /**
             * Shows slot with message about empty options
             * @default true
             * @type {Boolean}
             */
            showNoOptions: {
                type: Boolean,
                default: true,
            },
            showNoResults: {
                type: Boolean,
                default: true,
            },
            tabindex: {
                type: Number,
                default: 0,
            },
            langSelector: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            isOpen: {
                handler(val) {
                    val &&
                        this.$nextTick(() => {
                            const element = this.$refs['list'];
                            const container = this.$refs['scroll-area'].childNodes[0];
                            const elementChild = element.childNodes[0].querySelectorAll('.multiselect__element');
                            const oneChildHeight = (elementChild[0] && elementChild[0].clientHeight) || 0;
                            container.style.height = element ? oneChildHeight * this.filteredOptions.length + 'px' : 0;
                        });
                    !val && this.$emit('close', true);
                },
                immediate: true,
            },
        },
        computed: {
            isSingleLabelVisible() {
                return (
                    (this.singleValue || this.singleValue === 0) &&
                    (!this.isOpen || !this.searchable) &&
                    !this.visibleValues.length
                );
            },
            isPlaceholderVisible() {
                return !this.internalValue.length && (!this.searchable || !this.isOpen);
            },
            visibleValues() {
                return this.multiple ? this.internalValue.slice(0, this.limit) : [];
            },
            singleValue() {
                return this.internalValue[0];
            },
            deselectLabelText() {
                return this.showLabels ? this.deselectLabel : '';
            },
            deselectGroupLabelText() {
                return this.showLabels ? this.deselectGroupLabel : '';
            },
            selectLabelText() {
                return this.showLabels ? this.selectLabel : '';
            },
            selectGroupLabelText() {
                return this.showLabels ? this.selectGroupLabel : '';
            },
            selectedLabelText() {
                return this.showLabels ? this.selectedLabel : '';
            },
            inputStyle() {
                if (this.searchable || (this.multiple && this.value && this.value.length)) {
                    // Hide input by setting the width to 0 allowing it to receive focus
                    return this.isOpen ? { width: '100%' } : { width: '0', position: 'absolute', padding: '0' };
                }
                return {};
            },
            contentStyle() {
                return this.options.length ? { display: 'inline-block' } : { display: 'block' };
            },
            isAbove() {
                if (this.openDirection === 'above' || this.openDirection === 'top') {
                    return true;
                } else if (this.openDirection === 'below' || this.openDirection === 'bottom') {
                    return false;
                } else {
                    return this.preferredOpenDirection === 'above';
                }
            },
            showSearchInput() {
                return (
                    this.searchable &&
                    (this.hasSingleSelectedSlot && (this.visibleSingleValue || this.visibleSingleValue === 0)
                        ? this.isOpen
                        : true)
                );
            },
        },
        methods: {
            synchronizeScroll(event, syncRef) {
                this.lastSyncRef === syncRef && (this.$refs[syncRef].scrollTop = event.target.scrollTop);
                this.lastSyncRef = syncRef;
            },
            onBlurHandler() {
                this.deactivate();
                this.$emit('blur');
            },
            onFocusHandler() {
                this.activate();
                this.$emit('focus');
            },
        },
        data() {
            return {
                lastSyncRef: null,
            };
        },
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
    .multiselect,
    .multiselect__input,
    .multiselect__single {
        font-family: inherit;
        font-size: $font-16;
        touch-action: manipulation;
    }

    .multiselect {
        box-sizing: content-box;
        display: block;
        position: relative;
        width: 100%;
        min-height: 40px;
        text-align: left;
        color: $white;
    }

    .multiselect * {
        box-sizing: border-box;
    }

    .multiselect:focus {
        outline: none;
    }

    .multiselect--disabled {
        background: unset;
        pointer-events: none;
        opacity: 0.6;
    }

    .multiselect--active {
        z-index: 2;
    }

    .multiselect__input,
    .multiselect__single {
        position: relative;
        display: inline-block;
        min-height: 20px;
        line-height: $font-24;
        border: none;
        border-radius: $border-radius-6;
        background: $marine-600;
        width: calc(100%);
        padding: 0;
        transition: border 0.1s ease;
        box-sizing: border-box;
        margin-bottom: $space-12;
        vertical-align: top;
        color: $marine-300;
    }

    .multiselect__input::placeholder {
        color: $marine-400;
    }

    .multiselect__tag ~ .multiselect__input,
    .multiselect__tag ~ .multiselect__single {
        width: auto;
    }

    .multiselect__input:hover,
    .multiselect__single:hover {
        border-color: #cfcfcf;
    }

    .multiselect__input:focus,
    .multiselect__single:focus {
        border-color: #a8a8a8;
        outline: none;
    }

    .multiselect__single {
        padding: 0;
        margin-bottom: $space-12;
    }

    .multiselect__tags {
        min-height: 40px;
        display: block;
        padding: $space-12 $space-40 0 $space-16;
        border-radius: $border-radius-6;
        border: none;
        background: $marine-600;
        font-size: $font-16;
        line-height: $font-24;
    }

    .multiselect__tags_outline {
        background: transparent;
        border: 1px solid $marine-500;
        .multiselect__single {
            background: transparent;
        }
    }

    .multiselect__tag {
        position: relative;
        display: inline-block;
        padding: 4px 26px 4px 10px;
        border-radius: $border-radius-6;
        margin-right: 10px;
        color: $marine-300;
        line-height: 1;
        background: $blue-500;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
    }

    .multiselect__current {
        line-height: 16px;
        min-height: 40px;
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        padding: 8px 30px 0 12px;
        white-space: nowrap;
        margin: 0;
        text-decoration: none;
        border-radius: $border-radius-6;
        border: 1px solid transparent;
        cursor: pointer;
    }

    .multiselect__select {
        line-height: 16px;
        display: block;
        position: absolute;
        box-sizing: border-box;
        width: 44px;
        height: 36px;
        right: 0;
        top: $space-8;
        padding: $space-12 $space-16;
        margin: 0;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        transition: transform 0.2s ease;
        color: $marine-300;
    }

    .multiselect__select:before {
        position: relative;
        right: 0;
        top: 65%;
        color: #999;
        margin-top: 4px;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #999999 transparent transparent transparent;
        content: '';
    }

    .multiselect__placeholder {
        color: $marine-400;
        display: inline-block;
        margin-bottom: $space-12;
        padding: 0;
    }

    .multiselect--active .multiselect__placeholder {
        display: none;
    }

    .multiselect__content-wrapper {
        display: block;
        background: $marine-700;
        width: 100%;
        margin: $space-8 0 0;
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 4px;
        z-index: 2;
        -webkit-overflow-scrolling: touch;
        max-height: unset;
        position: absolute;
    }

    .multiselect__scroll {
        right: $space-8;
        top: $space-8;
        width: 6px;
        position: absolute;
        max-height: calc(240px - 16px);
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background: $marine-400;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $marine-300;
            border-radius: 4px;
        }
    }

    .multiselect__content {
        list-style: none;
        display: inline-block;
        padding: 0;
        margin: 0;
        min-width: 100%;
        vertical-align: top;
        max-height: 240px;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .multiselect--above .multiselect__content-wrapper {
        bottom: 100%;
        margin: 0 0 $space-8;
    }

    .multiselect__element {
        display: block;
    }

    .multiselect__option {
        display: block;
        padding: $space-16;
        min-height: 40px;
        line-height: 16px;
        text-decoration: none;
        text-transform: none;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        //margin: 0 (-$space-8);
    }

    .multiselect__option:after {
        top: 0;
        right: 0;
        position: absolute;
        line-height: 48px;
        padding-right: 12px;
        padding-left: 20px;
        font-size: 13px;
    }

    .multiselect__option--highlight {
        background: $blue-500;
        outline: none;
        color: $white;
    }

    .multiselect__option--highlight:after {
        content: attr(data-select);
        background: $blue-500;
        color: $white;
    }

    .multiselect__option--selected {
        font-weight: bold;
    }

    .multiselect__option--selected:after {
        content: attr(data-selected);
        color: silver;
    }

    .multiselect__option--selected.multiselect__option--highlight {
        background: #ff6a6a;
        color: #fff;
    }

    .multiselect__option--selected.multiselect__option--highlight:after {
        background: #ff6a6a;
        content: attr(data-deselect);
        color: #fff;
    }

    .multiselect--disabled .multiselect__current,
    .multiselect--disabled .multiselect__select {
        background: unset;
        color: #a6a6a6;
    }

    .multiselect__option--disabled {
        background: #ededed !important;
        color: #a6a6a6 !important;
        cursor: text;
        pointer-events: none;
    }

    .multiselect__option--group {
        background: #ededed;
        color: #35495e;
    }

    .multiselect__option--group.multiselect__option--highlight {
        background: #35495e;
        color: #fff;
    }

    .multiselect__option--group.multiselect__option--highlight:after {
        background: #35495e;
    }

    .multiselect__option--disabled.multiselect__option--highlight {
        background: #dedede;
    }

    .multiselect__option--group-selected.multiselect__option--highlight {
        background: #ff6a6a;
        color: #fff;
    }

    .multiselect__option--group-selected.multiselect__option--highlight:after {
        background: #ff6a6a;
        content: attr(data-deselect);
        color: #fff;
    }

    .multiselect-enter-active,
    .multiselect-leave-active {
        transition: all 0.15s ease;
    }

    .multiselect-enter,
    .multiselect-leave-active {
        opacity: 0;
    }

    .multiselect__strong {
        margin-bottom: 8px;
        line-height: 20px;
        display: inline-block;
        vertical-align: top;
    }

    *[dir='rtl'] .multiselect {
        text-align: right;
    }

    *[dir='rtl'] .multiselect__select {
        right: auto;
        left: 1px;
    }

    *[dir='rtl'] .multiselect__tags {
        padding: 8px 8px 0px 40px;
    }

    *[dir='rtl'] .multiselect__content {
        text-align: right;
    }

    *[dir='rtl'] .multiselect__option:after {
        right: auto;
        left: 0;
    }

    *[dir='rtl'] .multiselect__clear {
        right: auto;
        left: 12px;
    }

    *[dir='rtl'] .multiselect__spinner {
        right: auto;
        left: 1px;
    }

    @keyframes spinning {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(2turn);
        }
    }
</style>
