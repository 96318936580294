import { Api } from '@/services/apiService';

class quayApi {
    static index() {
        return Api.get(`/quay`, { baseURL: '/new-api' });
    }

    static store(data) {
        return Api.post(`/quay`, data, { baseURL: '/new-api' });
    }

    static show(id) {
        return Api.get(`/quay/${id}`, { baseURL: '/new-api' });
    }
}

export default quayApi;
